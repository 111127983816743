/**
 * Format duration
 *
 * @param {string} duration Duration in seconds
 * @returns {string} Formatted duration
 */
export default (duration) => {

  // Convert the duration string to a number
  const seconds = parseFloat(duration);

  // Check if the input is a string and contains a number
  if (!duration || isNaN(seconds)) {
    return '';
  }

  // Calculate the number of hours, minutes, and seconds from the total seconds
  const hoursCount = Math.floor(seconds / 3600);
  const minutesCount = Math.floor((seconds % 3600) / 60);
  const secondsCount = seconds % 60;

  // Format the duration based on the components and the count
  let formattedDuration = '';
  if (hoursCount) {
    formattedDuration += `${hoursCount}h `;
  }
  if (minutesCount) {
    formattedDuration += `${minutesCount}min `;
  }
  formattedDuration += `${secondsCount}s`;

  return formattedDuration;
};
