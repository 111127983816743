import React from 'react';
import PropTypes from 'prop-types';
import detect from 'browser-detect';

import Typography from '../Typography';
import Icon from '../Icon';

const Link = ({ href, children, showIcon = false }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Typography
        variant="subtitle"
        component="p"
        color="primaryActive"
        className="flex items-center"
      >
        {children}
        {!!showIcon && (
          <Icon
            name="external-link"
            className="ml-2 w-5 text-white"
          />
        )}
      </Typography>
    </a>
  );
};

const Mail = ({ href, children, showIcon = false }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Typography
        variant="subtitle"
        component="p"
        color="primaryActive"
        className="flex items-center"
      >
        {children}
        {!!showIcon && (
          <Icon
            name="mailto"
            className="ml-2 w-5 text-white"
          />
        )}
      </Typography>
    </a>
  );
};

const Row = ({ title, value, link }) => {
  return (
    <div className="mb-4 flex">
      <Typography
        variant="subtitle"
        component="p"
        className="w-48 text-white"
      >
        {title}
      </Typography>

      {link ? (
        <Link href={link}>{value}</Link>
      ) : (
        <Typography
          variant="subtitle"
          component="p"
          className="w-48 text-white"
        >
          {value}
        </Typography>
      )}
    </div>
  );
};

const AboutModal = ({ buildNumber, versionNumber, commitHash }) => {
  const { os, version, name } = detect();
  const browser = `${name[0].toUpperCase()}${name.substr(1)} ${version}`;

  const renderRowTitle = title => (
    <div className="mb-3 border-b-2 border-black pb-3">
      <Typography
        variant="inherit"
        color="primaryLight"
        className="text-[16px] font-semibold !leading-[1.2]"
      >
        {title}
      </Typography>
    </div>
  );
  return (
    <div>
      {renderRowTitle('Disclaimer for the in vivo MR Images')}
      <div className="mb-8 flex text-[14px]">The in vivo MR images displayed on this website are provided for informational and marketing purposes. The acquisition of all data showcased on this website has received ethical approval from an Institutional Review Board (IRB), ensuring compliance with established ethical standards. Participants involved in the studies were informed about the research's purpose and provided explicit consent for the use of their acquired data for marketing purposes. To protect the privacy of individuals, all MR images have undergone thorough anonymization. Identifying tags have been removed from the DICOM headers, and private tags have also been eliminated. While considerable efforts have been made to uphold ethical standards and ensure participant privacy, users are reminded that these images are intended solely for informational and marketing purposes. Unauthorized use, reproduction, or distribution of these images is strictly prohibited. For any inquiries or concerns regarding the displayed data, please contact Skope for clarification.</div>
      {renderRowTitle('Additional Information')}
      <div className="mb-8 flex text-[14px]">To learn more about Skope products, kindly visit our website or reach out to us via email.</div>
      <div className="mb-8 flex">
        <Link
          href="https://skope.swiss/"
          showIcon={true}
        >
          Skope webpage
        </Link>
        <span className="ml-4">
          <Mail
            href="mailto:contact@skope.ch"
            showIcon={true}
          >
            Get in touch
          </Mail>
        </span>
      </div>

      {renderRowTitle('DICOM Viewer')}
      <div className="mb-8 flex text-[14px]">This website incorporates the OHIF (Open Health Imaging Foundation) Viewer to facilitate the display of DICOM images. The OHIF Viewer has been adapted for this platform to enhance the user experience when viewing medical DICOM images. This viewer is a third-party integration and is made possible through the open-source efforts of the Open Health Imaging Foundation. Skope extends its appreciation to the OHIF development team for their valuable contributions to the field of medical imaging. While efforts are made to ensure the reliability and security of the OHIF Viewer, users are advised that Skope does not assume responsibility for any issues, disruptions, or damages that may arise from its use. For additional information and support, users are encouraged to refer to the OHIF Viewer's documentation and community forums.</div>
      <div className="flex flex-col">
        <Row
          title="Repository URL"
          value="https://github.com/OHIF/Viewers/"
          link="https://github.com/OHIF/Viewers/"
        />
        <Row
          title="OHIF website"
          value="https://ohif.org/"
          link="https://ohif.org/"
        />
        <Row
          title="Version number"
          value={versionNumber}
        />
      </div>
    </div>
  );
};


AboutModal.propTypes = {
  buildNumber: PropTypes.string,
  versionNumber: PropTypes.string,
};

export default AboutModal;
