// External
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from '@ohif/i18n';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import Compose from './routes/Mode/Compose';
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { ServicesManager, ExtensionManager, CommandsManager, HotkeysManager } from '@ohif/core';
import {
  DialogProvider,
  Modal,
  ModalProvider,
  SnackbarProvider,
  ThemeWrapper,
  ViewportDialogProvider,
  ViewportGridProvider,
  CineProvider,
  UserAuthenticationProvider,
} from '@ohif/ui';
// Viewer Project
// TODO: Should this influence study list?
import { AppConfigProvider } from '@state';
import createRoutes from './routes';
import appInit from './appInit.js';
import OpenIdConnectRoutes from './utils/OpenIdConnectRoutes';
import ReactGA from 'react-ga4'; // Chris

let commandsManager: CommandsManager,
  extensionManager: ExtensionManager,
  servicesManager: ServicesManager,
  hotkeysManager: HotkeysManager;

function App({ config, defaultExtensions, defaultModes }) {

  const handleAcceptCookie = () => {
    ReactGA.initialize("G-R2RZN54Z54");
  };

  const [init, setInit] = useState(null);
  useEffect(() => {

    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }

    const run = async () => {
      appInit(config, defaultExtensions, defaultModes).then(setInit).catch(console.error);
    };

    run();
  }, []);


  if (!init) {
    return null;
  }

  // Set above for named export
  commandsManager = init.commandsManager;
  extensionManager = init.extensionManager;
  servicesManager = init.servicesManager;
  hotkeysManager = init.hotkeysManager;

  // Set appConfig
  const appConfigState = init.appConfig;
  const { routerBasename, modes, dataSources, oidc, showStudyList } = appConfigState;

  const {
    uiDialogService,
    uiModalService,
    uiNotificationService,
    uiViewportDialogService,
    viewportGridService,
    cineService,
    userAuthenticationService,
    customizationService,
  } = servicesManager.services;

  const providers = [
    [AppConfigProvider, { value: appConfigState }],
    [UserAuthenticationProvider, { service: userAuthenticationService }],
    [I18nextProvider, { i18n }],
    [ThemeWrapper],
    [ViewportGridProvider, { service: viewportGridService }],
    [ViewportDialogProvider, { service: uiViewportDialogService }],
    [CineProvider, { service: cineService }],
    [SnackbarProvider, { service: uiNotificationService }],
    [DialogProvider, { service: uiDialogService }],
    [ModalProvider, { service: uiModalService, modal: Modal }],
  ];
  const CombinedProviders = ({ children }) => Compose({ components: providers, children });

  let authRoutes = null;

  // Should there be a generic call to init on the extension manager?
  customizationService.init(extensionManager);

  // Use config to create routes
  const appRoutes = createRoutes({
    modes,
    dataSources,
    extensionManager,
    servicesManager,
    commandsManager,
    hotkeysManager,
    routerBasename,
    showStudyList,
  });

  if (oidc) {
    authRoutes = (
      <OpenIdConnectRoutes
        oidc={oidc}
        routerBasename={routerBasename}
        userAuthenticationService={userAuthenticationService}
      />
    );
  }

  const spanStyle = {
    fontFamily: 'Inter,sans-serif',
    fontSize: '14px'
  };

  const titleStyle = {
    color: '#5acce6',
    fontFamily: 'Inter,sans-serif',
    fontSize: '16px'
  };

  return (
    <CombinedProviders>
      <BrowserRouter basename={routerBasename}>
        {authRoutes}
        {appRoutes}
      </BrowserRouter>
      <CookieConsent
        overlay={true}
        onAccept={handleAcceptCookie}
        style={{
          background: "#041C4A",
          color: "white",
        }}
        buttonStyle={{
          background: "#5ACCE6",
          color: "black",
          fontWeight: "bolder"
        }}
      >
        <div>
          <p><strong><span style={titleStyle}>Disclaimer for the shown in vivo MR Images</span></strong></p>
          <p><span style={spanStyle}>The in vivo MR images displayed on this website are provided for informational and marketing purposes. The acquisition of all data showcased on this website has received ethical approval from an Institutional Review Board (IRB), ensuring compliance with established ethical standards. Participants involved in the studies were informed about the research&#39;s purpose and provided explicit consent for the use of their acquired data for marketing purposes. To protect the privacy of individuals, all MR images have undergone thorough anonymization. Identifying tags have been removed from the DICOM headers, and private tags have also been eliminated. While considerable efforts have been made to uphold ethical standards and ensure participant privacy, users are reminded that these images are intended solely for informational and marketing purposes. Unauthorized use, reproduction, or distribution of these images is strictly prohibited. For any inquiries or concerns regarding the displayed data, please contact Skope for clarification.</span></p>
          <p><strong><span style={titleStyle}>Use of cookies</span></strong></p>
          <p><span style={spanStyle}>We use cookies to ensure the smooth operation of the website and to collect essential data for analytics and audience engagement purposes.</span></p>
        </div>
      </CookieConsent>
    </CombinedProviders >
  );
}



App.propTypes = {
  config: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      routerBasename: PropTypes.string.isRequired,
      oidc: PropTypes.array,
      whiteLabeling: PropTypes.object,
      extensions: PropTypes.array,
    }),
  ]).isRequired,
  /* Extensions that are "bundled" or "baked-in" to the application.
   * These would be provided at build time as part of they entry point. */
  defaultExtensions: PropTypes.array,
};

App.defaultProps = {
  config: {
    /**
     * Relative route from domain root that OHIF instance is installed at.
     * For example:
     *
     * Hosted at: https://ohif.org/where-i-host-the/viewer/
     * Value: `/where-i-host-the/viewer/`
     * */
    routerBaseName: '/',
    /**
     *
     */
    showLoadingIndicator: true,
    showStudyList: true,
    oidc: [],
    extensions: [],
  },
  defaultExtensions: [],
};

export default App;

export { commandsManager, extensionManager, servicesManager };
